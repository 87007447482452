<template>
  <Intersect @enter.once="() => setViewportStatus(true)" :threshold="[0]">
    <div :id="`img_${imageUuid}`">
      <Intersect
        @enter.once="doLazyTrigger"
        :rootMargin="`0px 0px 100% 0px`"
        :threshold="[0]"
      >
        <div
          class="bleach-image__lazy-target"
          :id="`img_lazy_target_${imageUuid}`"
        >
          <!-- Lazy-load trigger target -->
        </div>
      </Intersect>
      <template v-if="!lazyOverride">
        <picture
          itemprop="image"
          itemscope
          itemtype="http://schema.org/ImageObject"
          :id="imageUuid"
        >
          <source
            v-for="(transformations, breakpoint) in validatedSizes"
            :key="breakpoint"
            :media="`(max-width: ${styles['BREAKPOINT_' + breakpoint]}px)`"
            :srcset="generateAssetUrls(transformations)"
          />
          <img
            itemprop="contentUrl url"
            v-if="!isVideoPlaying"
            :srcset="generateAssetUrls(defaultSize)"
            :src="generateAssetUrls(defaultSize).split(' ')[0]"
            :alt="alt"
            :loading="immediate || isInViewport ? 'eager' : 'lazy'"
            @load="handleMediaLoaded"
            :class="{
              'bleach-image--loaded': isLoaded,
              'bleach-image--immediate': immediate,
              'bleach-image--contain': contain,
              'bleach-image--fixed': fixed,
              'bleach-image--autowidth': autoWidth
            }"
          />
        </picture>

        <video
          ref="videoElement"
          :key="
            currentActiveBreakpoint
              ? currentActiveBreakpoint.breakpoint
              : 'default'
          "
          v-if="isVideo && isVideoInit"
          @playing="handleVideoPlaying"
          @pause="handleVideoPlaying"
          muted
          loop
          playsinline
          autoplay
          :class="{
            'bleach-image--contain': contain,
            'bleach-image--fixed': fixed,
            'bleach-image--autowidth': autoWidth
          }"
        >
          <source
            v-for="extension in ['mp4']"
            :key="extension"
            :type="`video/${extension}`"
            :src="
              generateAssetUrls(
                getCurrentActiveTransformations,
                true,
                extension
              )
            "
          />
        </video>
      </template>
    </div>
  </Intersect>
</template>

<script>
import Intersect from "vue-intersect"
import styles from "./../styles/settings.scss"
import { mapActions, /* mapGetters, */ mapState } from "vuex"

import { v4 as uuidv4 } from "uuid"

// devicePixelMax controls the number of pixel densities that the srcset will cover... in theory we could support up to 3x or 4x for Super Retina, but it's probably safe to stay at 2x at the moment.
let devicePixelMax = 2

export default {
  props: {
    src: {
      type: String,
      required: false
    },
    extSrc: {
      type: String,
      required: false
    },
    extSrcThumbnail: {
      type: String,
      required: false
    },
    /* eagerLoad: Boolean, */
    sizes: {
      type: Object,
      default: () => ({})
    },
    defaultSize: {
      type: Array,
      required: true
    },
    alt: String,
    effects: {
      type: String,
      default: ""
    },
    autoWidth: Boolean,
    contain: Boolean,
    face: Boolean,
    fixed: Boolean,
    lazyLoad: Boolean,
    noRetina: Boolean,
    imageQuality: String,
    immediate: Boolean // The image doesn't wait for the loaded flag (for looping carousels, etc)
  },

  data: () => ({
    styles,
    validatedSizes: {},
    currentActiveBreakpoint: null,
    isInViewport: false,
    isVideo: false,
    isVideoInit: false,
    isVideoPlaying: false,
    isInManifest: false,
    isLoaded: false,
    imageUuid: null,
    lazyOverride: false,
    rootUrl: "",
    assetId: ""
  }),

  components: {
    Intersect
  },

  created() {
    this.imageUuid = uuidv4()

    this.lazyOverride = this.lazyLoad

    this.init()

    this.validateBreakpoints()
    this.setCurrentActiveBreakpoint()

    let _rootPath

    if (this.extSrc) {
      // It's an image from an external source which we would like to cache in Cloudinary
      _rootPath = "/fetch"
      this.assetId = encodeURI(this.extSrc).replace(/\?/g, "%3F")
    } else if (this.src.includes("/fetch/")) {
      // It's a previously cached image that we are now requesting direct from Cloudinary
      _rootPath = "/fetch"
      this.assetId = this.src.split("/fetch/")[1]
    } else {
      // It's a regular Cloudinary-hosted resource
      _rootPath = "/upload"
      this.assetId = this.src.split("/upload/")[1]
    }

    if (
      ["mp4", "gif", "webm", "avi", "mov"].includes(
        this.assetId
          .split("/")
          .slice(-1)[0]
          .split(".")[1]
          .split("%3F")[0]
      )
    ) {
      this.isVideo = true
    }

    this.rootUrl =
      process.env.VUE_APP_CLOUDINARY_BASE_PATH +
      (this.isVideo ? "/video" : "/image") +
      _rootPath
  },

  methods: {
    ...mapActions("content", [
      "APPEND_IMAGE_MANIFEST",
      "CLEAR_LOADED_IMAGE_FROM_MANIFEST"
    ]),

    async init() {
      // if (!this.lazyOverride) {
      //   this.debug.log("IMAGE INIT", this.src, this.imageUuid)
      //   const _added = await this.APPEND_IMAGE_MANIFEST({
      //     imageUuid: this.imageUuid,
      //     src: this.src
      //   })
      //   this.debug.log("_added", _added)
      //   if (_added) {
      //     this.isInManifest = true
      //   }
      // }
    },

    doLazyTrigger() {
      if (this.lazyOverride) {
        this.lazyOverride = false
        this.init()
      }
    },

    async setViewportStatus(val) {
      if (!this.isLoaded) {
        this.debug.log("setting viewport status", this.src, this.imageUuid)
        this.isInViewport = val
        const _added = await this.APPEND_IMAGE_MANIFEST({
          imageUuid: this.imageUuid,
          src: this.src
        })
        this.debug.log("_added", _added)
        if (_added) {
          this.isInManifest = true
        }
      }
    },

    validateBreakpoints() {
      // Only allow supported breakpoints (that is, sizes listed in the settings.scss) - any others declared on the component are ignored
      // The breakpoint variables are named BREAKPOINT_SM etc, in case we need to export other variable collections from the styles in the future, so that prefix must be stripped here.
      this.validatedSizes = Object.keys(this.styles)
        .filter(key => key.split("_")[0].toUpperCase() === "BREAKPOINT")
        .map(key => key.split("_")[1])
        .reduce((result, key) => {
          if (this.sizes[key]) result[key] = this.sizes[key]
          return result
        }, {})
    },

    generateAssetUrls(transformation, getVideo = false, extension) {
      // TODO: Abstract this better into the Contentful/Cloudinary integration, once we've figured out where the assets and their meta are best kept - but for now... eurgh
      let urlList = [],
        _assetId

      /////////////////
      // ASSET IDENTIFICATION - if it's an eps or svg, change to a format that supports transparency. If it's a video file, change to an image file to retrieve the 'poster'
      /////////////////
      if (this.src) {
        _assetId = this.assetId.replace(
          /\.(mp4|webm|avi|mov)$/,
          getVideo ? `.${extension}` : ".jpg"
        )

        _assetId = _assetId.replace(
          /\.(eps|svg|gif)$/,
          getVideo ? `.${extension}` : ".png"
        )
      } else if (this.extSrc) {
        if (this.isVideo && !getVideo && this.extSrcThumbnail) {
          _assetId = encodeURI(this.extSrcThumbnail).replace(/\?/g, "%3F")
        } else {
          _assetId = this.assetId
        }
      }

      /////////////////
      // ART DIRECTION - get any 'custom' art direction passed in
      // NB these commands are just the default Cloudinary transformations API
      // with c_fill set by default (altho this can be overriden by any other crop value, if multiple crop values are added by mistake then only the last one is used)
      // https://cloudinary.com/documentation/image_transformation_reference
      /////////////////
      let artDirection = transformation.slice(2)
      let crop = artDirection.find(trans => trans.startsWith("c_"))
        ? artDirection.filter(trans => trans.startsWith("c_")).reverse()[0]
        : "c_fill"

      /////////////////
      // ZOOM - use the passed-in zoom factor to crop closer into the image
      // TODO: THIS WORKS BUT IT IS HORRIBLE and it diverges from Cloudinary API's concept of zoom
      /////////////////
      let zoomInput =
        artDirection.find(trans => trans.startsWith("zoom_")) &&
        artDirection.find(trans => trans.startsWith("zoom_")).split("_")[1]
      let zoomOutput = zoomInput
        ? `c_crop,w_${1 / Number(zoomInput)},h_${1 / Number(zoomInput)}/`
        : ""
      artDirection = artDirection
        .filter(trans => !trans.startsWith("c_") && !trans.startsWith("zoom_"))
        .concat([crop])

      /////////////////
      // IMAGE QUALITY - If no quality specified, use q_auto
      /////////////////
      let defaultImageQ = (arr => {
        for (let i = 0; i < devicePixelMax; i++) {
          arr.push("q_auto")
        }
        return arr
      })([])

      let imageQ =
        this.imageQuality != null
          ? [
              `q_${Math.floor(
                parseInt(this.imageQuality) +
                  (100 - parseInt(this.imageQuality)) / 2
              )}`,
              `q_${this.imageQuality}`
            ]
          : defaultImageQ

      /////////////////
      // FILE FORMAT - to ensure that all images are served as .webp where supported, use f_auto in conjunction with the 'supplied' file extension
      /////////////////
      let format = /* getVideo ? "f_mp4,vc_h265" :  */ "f_auto"

      /////////////////
      // PUT THEM TOGETHER and what have you got
      /////////////////
      for (
        let i = 1;
        i <=
        (this.noRetina || this.testCondition || this.isVideo
          ? 1
          : devicePixelMax);
        i++
      ) {
        let widthPx = multiplyDimensionByDensity("w", transformation[0], i)
        let heightPx = multiplyDimensionByDensity("h", transformation[1], i)
        let transformationsList = [
          widthPx,
          heightPx,
          ...artDirection,
          this.face ? "g_faces" : "",
          imageQ[i - 1],
          format
        ]
          .filter(val => val.length > 0)
          .toString()

        urlList.push(
          `${this.rootUrl}/${zoomOutput ? zoomOutput + "/" : ""}${
            this.effects ? this.effects + "/" : ""
          }${
            !getVideo && this.isVideo ? "so_0/" : ""
          }${transformationsList}/${_assetId}${getVideo ? "" : " " + i + "x"}`
        )
      }

      return urlList.toString()

      /////////////////
      // UTILITY FUNCTIONS
      /////////////////

      function multiplyDimensionByDensity(axis, val, density) {
        return val === "auto"
          ? ""
          : val.toString().startsWith("ar_")
          ? val
          : axis + "_" + val * density
      }
    },

    handleMediaLoaded() {
      this.isLoaded = true

      if (this.isInManifest) {
        this.CLEAR_LOADED_IMAGE_FROM_MANIFEST({
          src: this.src,
          imageUuid: this.imageUuid
        })
      }
      // if (this.$refs.videoElement) {
      //   this.debug.log("found the video", this.$refs.videoElement)
      //   this.$refs.videoElement.play()
      // }
      if (this.isVideo) {
        window.setTimeout(() => {
          this.isVideoInit = true
        }, 5000)
      }
    },

    handleVideoPlaying(event) {
      this.isVideoPlaying = !event.target.paused
    },

    setCurrentActiveBreakpoint() {
      let keys = Object.keys(this.validatedSizes)

      let tempActiveBreakpoint = null

      for (let i = 0; i < keys.length; i++) {
        let bpToCheck = {
          breakpoint: keys[i],
          size: Number(styles["BREAKPOINT_" + keys[i]])
        }

        // first check that the breakpoint is bigger than current width
        // if it is, then check if it's smaller than the existing temp breakpoint
        tempActiveBreakpoint =
          bpToCheck.size > this.currentWidth &&
          (!tempActiveBreakpoint || bpToCheck.size < tempActiveBreakpoint.size)
            ? bpToCheck
            : tempActiveBreakpoint
      }

      this.currentActiveBreakpoint = tempActiveBreakpoint
    }
  },

  computed: {
    ...mapState("breakpoints", ["currentWidth"]),
    ...mapState("dieselgate", ["testCondition", "pageLoadIdle"]),
    ...mapState("content", ["currentPageImageManifest"]),

    // ...mapGetters("content", [
    //   "GET_IMAGES_ARE_LOADED",
    //   "GET_VIEWPORT_IMAGES_ARE_LOADED"
    // ]),

    getCurrentActiveTransformations() {
      return this.currentActiveBreakpoint
        ? this.validatedSizes[this.currentActiveBreakpoint.breakpoint]
        : this.defaultSize
    }

    // getShouldLoad() {
    //   return (
    //     this.immediate ||
    //     this.isInViewport ||
    //     this.GET_VIEWPORT_IMAGES_ARE_LOADED
    //   )
    // }
  },

  watch: {
    currentWidth() {
      this.setCurrentActiveBreakpoint()
    }

    // GET_IMAGES_ARE_LOADED: {
    //   handler: function(newVal) {
    //     if (newVal) {
    //       /* console.log(
    //         "¶¶¶¶ §§§§ IMAGES ARE LOADED",
    //         this.currentPageImageManifest.length
    //       ) */
    //       this.isVideoInit = true
    //     }
    //   },
    //   immediate: false
    // }

    // GET_VIEWPORT_IMAGES_ARE_LOADED: {
    //   handler: function(newVal) {
    //     if (newVal) {
    //       console.log(
    //         "÷÷÷÷ VIEWPORT IMAGES ARE LOADED",
    //         this.currentPageImageManifest.length
    //       )
    //       // this.isVideoInit = true
    //     }
    //   },
    //   immediate: false
    // }
  }
}
</script>

<style lang="scss">
.bleach-image {
  &__lazy-target {
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    opacity: 0;
    margin-bottom: -1px;
  }
}

picture {
  width: inherit;
  max-width: inherit;
  height: inherit;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    transition: opacity 0s linear;
    opacity: 0;

    &.bleach-image--immediate {
      transition: unset;
    }

    &.bleach-image--loaded,
    &.bleach-image--immediate {
      opacity: 1;
    }
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

picture img,
video {
  &.bleach-image--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: inherit;
    max-width: inherit;
    height: inherit;
    pointer-events: none; //specific fix for a Safari issue - fixed images capture pointer events outside their containers even when clipped
  }

  &.bleach-image--autowidth {
    width: auto;
  }

  &.bleach-image--contain {
    object-fit: contain;
  }
}
</style>
